import React from "react"
import profilePic from "../../content/assets/profile-pic.png"
import { rhythm } from "../utils/typography"

const Bio = () => {
  return (
    <div
      style={{
        display: "flex",
        marginBottom: rhythm(2),
      }}
    >
      <img
        src={profilePic}
        alt={`Søren Andersen`}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          width: rhythm(2),
          height: rhythm(2),
          borderRadius: "50%",
        }}
      />

      <p style={{ maxWidth: 400 }}>
        Digital garden of Søren Andersen.
        <br />
        Posts on tech that I use and learn.
      </p>
    </div>
  )
}

export default Bio
